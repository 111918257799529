export default [
  {
    id: 1,
    icon: "./img/html5-sq.svg",
    text: "HTML5",
  },
  {
    id: 2,
    icon: "./img/css3-sq.svg",
    text: "CSS3",
  },
  {
    id: 3,
    icon: "./img/js-sq.svg",
    text: "JavaScript",
  },
  {
    id: 4,
    icon: "./img/react-sq.svg",
    text: "React",
  },
  {
    id: 5,
    icon: "./img/node-sq.svg",
    text: "Node.js",
  },
  {
    id: 6,
    icon: "./img/next-sq.svg",
    text: "Next.js",
  },
  //  {
  //      id: 7,
  //      icon: "./img/cs-sq.svg",
  //      text: "C#"
  //  },
  //  {
  //      id: 8,
  //      icon: "./img/bootstrap-sq.svg",
  //      text: "Bootstrap"
  //  },
  {
    id: 9,
    icon: "./img/github-sq.svg",
    text: "GitHub",
  },
  {
    id: 10,
    icon: "./img/figma-sq.svg",
    text: "Figma",
  },
  {
    id: 11,
    icon: "./img/xd-sq.svg",
    text: "xd",
  },
  {
    id: 12,
    icon: "./img/ps-sq.svg",
    text: "Photoshop",
  },
  {
    id: 13,
    icon: "./img/ai-sq.svg",
    text: "Illustrator",
  },
  {
    id: 14,
    icon: "./img/indesign-sq.svg",
    text: "InDesign",
  },
]
