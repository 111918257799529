import "bootstrap/dist/css/bootstrap.min.css"
import { HashLink } from "react-router-hash-link"
import "./Menubar.css"
import logo from "../assets/logo_name.png"
import { Nav, Navbar, Container } from "react-bootstrap"

function Menubar() {
  return (
    <Navbar
      bg="light"
      fixed="top"
      expand="md"
      collapseOnSelect
      className="shadow-sm"
    >
      <Container>
        <Navbar.Brand>
          <HashLink smooth to={"/#intro"} class="navbar-brand">
            <img src={logo} alt="Logo" width="170px" />
          </HashLink>
        </Navbar.Brand>

        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <HashLink smooth to={"/#kompetencer"} className="nav-link">
              Skills
            </HashLink>
            <HashLink smooth to={"/#portfolio"} className="nav-link">
              Portfolio
            </HashLink>
            <HashLink smooth to={"/#om-mig"} className="nav-link">
              About me
            </HashLink>
            <HashLink smooth to={"/#kontakt"} className="nav-link">
              Contact
            </HashLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    // Navbar
    // <nav className="navbar navbar-expand-md navbar-light bg-light shadow-sm sticky-top">
    //     <div className="container-md">
    //         {/* name/logo */}
    //         <HashLink smooth to={"/#intro"} class="navbar-brand">
    //             <span class="fw-bold text-muted">
    //                 <img src={logo} alt="Logo" width="170px" />
    //             </span>
    //         </HashLink>
    //         {/* toogle button for mobile nav */}
    //         <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main-nav"
    //             aria-controls="main-nav" aria-expanded="false" aria-label="Toggle navigation">
    //             <span className="navbar-toggler-icon"></span>
    //         </button>
    //         {/* navbar links */}
    //         <div className="collapse navbar-collapse justify-content-end align-center" id="main-nav">
    //             <ul className="navbar-nav">
    //                 <li className="nav-item">
    //                     <HashLink smooth to={"/#kompetencer"} className="nav-link">Kompetencer</HashLink>
    //                 </li>
    //                 <li className="nav-item">
    //                     <HashLink smooth to={"/#portfolio"} className="nav-link">Portfolio</HashLink>
    //                 </li>
    //                 <li className="nav-item">
    //                     <HashLink smooth to={"/#om-mig"} className="nav-link">Om mig</HashLink>
    //                 </li>
    //                 <li className="nav-item">
    //                     <HashLink smooth to={"/#kontakt"} className="nav-link">Kontakt</HashLink>
    //                 </li>
    //             </ul>
    //         </div>
    //     </div>
    //   </nav>
  )
}
export default Menubar
