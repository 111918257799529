import './IconLink.css';

function IconLink(props) {
    return(
        <div className="icon-link-container">
            <div className="icon-card">
                <img className="icon" src={props.icon} alt={`${props.text} icon`} />
                <p className='icon-text'>{props.text}</p>
            </div>
        </div>
    )
}
export default IconLink;