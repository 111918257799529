import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Intro from './components/Intro';
import Kompetencer from './components/Kompetencer';
import Menubar from './components/Menubar';
import Portfolio from './components/Portfolio';
import OmMig from './components/OmMig';
import Kontakt from './components/Kontakt';

function App() {
  return (
    <Router>
      <div className="App">
        <Menubar />
        <div className="content">
          <Intro/>
          <Kompetencer  />
          <Portfolio />
          <OmMig />
          <Kontakt />
        </div>
      </div>
    </Router>
  );
}

export default App;
