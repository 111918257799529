import "bootstrap/dist/css/bootstrap.min.css"
import "./Intro.css"
import { HashLink } from "react-router-hash-link"
import CV from "../assets/CV.pdf"

function Intro() {
  return (
    <div id="intro" className="intro-container">
      <div className="intro-text">
        <p className="intro-p-one">Hi, my name is </p>
        <h1 className="intro-name">Esengül Yildirim</h1>
        <p className="intro-p-two">
          I am a student at CPH Business Lyngby, where I study web development.
          On the course, I have been working with Frontend, Backend and User
          Experience.
        </p>
      </div>
      <div className="intro-button">
        <HashLink smooth to={"/#portfolio"}>
          <button className="portfolio-button btn btn-secondary">
            Portfolio
          </button>
        </HashLink>
        <HashLink smooth to={"/#kompetencer"}>
          <button className="kompetencer-button btn btn-secondary">
            Skills
          </button>
        </HashLink>
        <a href={CV} className="cv-button btn btn-secondary" download>
          Download CV
        </a>
      </div>
    </div>
  )
}
export default Intro
