import "./OmMig.css"
import imgOfMe from "../assets/esen.jpg"
import CV from "../assets/CV.pdf"

function OmMig() {
  return (
    <div id="om-mig" class="ommig-container">
      <h2 class="title">ABOUT ME</h2>
      <div className="ommig-content">
        <img
          src={imgOfMe}
          className="imgOfMe"
          alt="Billede af Esengül Yildirim"
          height="300px"
        />
        <div className="line"></div>
        <p className="ommig-description">
          I am passionate about coding, designing and developing websites and I
          love the challenge of it. As a person, I am detail-oriented,
          structured and good at forming an overview in a busy environment.
          <br />
          In addition to having a huge interest and professional approach to web
          development, I also focus on fundamental values such as openness,
          understanding, clarity, humor and respect for diversity, as these are
          some of the most important tools in the approach to a good
          product/result.
          <br />I am passionate about developing my skills and therefore put a
          lot of focus on keeping up to date and learning new things.
        </p>
      </div>
      <a
        href={CV}
        target="_blank"
        class="ommig-button button btn btn-secondary"
      >
        Curriculum Vitae
      </a>
    </div>
  )
}
export default OmMig
