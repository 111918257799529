import "./Portfolio.css"

function Portfolio() {
  const data = [
    {
      id: 4,
      img: "./UI.jpg",
      alt: "Macbook showing a site with difference UI components",
      link: "https://esenyil.github.io/UI_library_without_webpack/",
      title: "Application made with HTML, CSS og JavaScript",
    },
    {
      id: 10,
      img: "./recipe.jpg",
      alt: "Macbook showing a CRUD recipe application",
      link: "https://esenyil.github.io/recipe-app/",
      title: "Application made with ReactJS og Firebase som database",
    },
    {
      id: 11,
      img: "./login.jpg",
      alt: "Macbook showing a login site",
      link: "https://esenyil.github.io/login-page/",
      title: "Application made with ReactJS og Firebase som Authorization",
    },
    {
      id: 5,
      img: "./quiz.jpg",
      alt: "Macbook showing a quiz",
      link: "https://esenyil.github.io/quiz/",
      title: "Application made with HTML, CSS og JavaScript",
    },
    {
      id: 6,
      img: "./todo.jpg",
      alt: "Macbook showing a todo liste",
      link: "https://esenyil.github.io/to-do-list/",
      title: "Application made with HTML, CSS og JavaScript",
    },
    {
      id: 7,
      img: "./tictactoe.jpg",
      alt: "Macbook showing a Tic Tac Toe game",
      link: "https://esenyil.github.io/TicTacToe/",
      title: "Application made with HTML, CSS and JavaScript",
    },
    {
      id: 8,
      img: "./notes.jpg",
      alt: "Macbook showing a CRUD note application",
      link: "https://esenyil.github.io/note_application_firbase_with_edit/",
      title:
        "Application made with HTML, CSS, JavaScript and Firebase as the database",
    },
    {
      id: 9,
      img: "./chat.jpg",
      alt: "Macbook showing a chat site",
      link: "https://esenyil.github.io/real_time_chatroom/",
      title:
        "Application made with HTML, CSS, JavaScript and Firebase as the database",
    },

    {
      id: 2,
      img: "./kjolhede.jpg",
      alt: "Macbook showing the www.kjoelhede.dk site",
      link: "https://www.kjoelhede.dk",
      title: "Wordpress website",
    },
    {
      id: 3,
      img: "./lavida.jpg",
      alt: "Macbook showing the www.lavida.dk site",
      link: "https://www.lavidapizza.dk",
      title: "Wordpress website",
    },
  ]

  return (
    <div id="portfolio" className="portfolio-container">
      <h2 className="title">PORTFOLIO</h2>
      <div className="card-portfolio">
        {data.map((item) => {
          return (
            <div className="portfolio-img-card">
              <img src={item.img} alt={item.alt} className="img-portfolio" />
              <div className="overlay overlay-blur">
                <p className="img-title">{item.title}</p>
                <a
                  href={item.link}
                  target="_blank"
                  class="button btn btn-light"
                >
                  Visit site
                </a>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default Portfolio
